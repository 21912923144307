/* Component Dependencies */
import { Component, Input } from '@angular/core';

/* Interfaces */
import { SkillBarItem } from '../../shared/interfaces/skill-bar-item';

@Component({
  selector: 'app-skill-bar',
  templateUrl: './skill-bar.component.html',
  styleUrls: ['./skill-bar.component.scss']
})
export class SkillBarComponent {
  @Input('content') skills: Array<SkillBarItem>;
}
