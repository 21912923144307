/* Component Dependencies */
import { Component } from '@angular/core';

/* Constants */
import { pageInfo } from '../../shared/page-info';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  routerLinks = pageInfo;

  constructor() { }

}
