import { Routes } from '@angular/router';

import { RedirectGuard } from './redirect-guard';

import { AboutComponent } from '../components/about/about.component';
import { ContactComponent } from '../components/contact/contact.component';
import { HomeComponent } from '../components/home/home.component';
import { PortfolioComponent } from '../components/portfolio/portfolio.component';
import { RedirectComponent } from '../components/redirect/redirect.component';

export const routes: Routes = [
  { path: 'about',     component: AboutComponent     },
  { path: 'contact',   component: ContactComponent   },
  { path: 'home',      component: HomeComponent      },
  { path: 'portfolio', component: PortfolioComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'mundus-vestro',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/mundus-vestro/`
    }
  },
  {
    path: 'home-automation',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/home-automation/`
    }
  },
  {
    path: 'news-app',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/news-app/home/`
    }
  },
  {
    path: 'drupal',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/drupal/`
    }
  },
  {
    path: 'drupalcon',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/drupalcon/`
    }
  },
  {
    path: 'd8multi',
    canActivate: [RedirectGuard],
    component: RedirectComponent,
    data: {
      externalUrl: `https://andrewwanex.com/d8multi/`
    }
  }
];
