/* Component Dependencies */
import { Component } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  links = [
    { text: 'Github',   href: 'https://github.com/ARW2705'                },
    { text: 'LinkedIn', href: 'https://www.linkedin.com/in/andrew-wanex/' },
    { text: 'Twitter',  href: 'https://twitter.com/andrewwanex?lang=en'   }
  ];

  constructor() { }

}
