/* Component Dependencies */
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

/* Services */
import { EmailRequestService } from '../../services/email-request.service';

/* Animations */
import { fadeInOut } from '../animations/routing-animations';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeInOut()
  ]
})
export class ContactComponent implements OnInit, OnDestroy {

  contactInfo = {
    address: 'Upland, CA 91784',
    email: 'awanex@gmail.com',
    phone: '770-238-2610'
  };
  contactForm: FormGroup;
  // Base form error object with field name and starter empty string
  formErrors = {
    'firstName': '',
    'lastName': '',
    'contactEmail': '',
    'message': ''
  };
  // Custom error feedback messages
  formErrorMessages = {
    'firstName': {
      'required': 'First name is required',
      'minlength': 'First name must be at least 2 characters'
    },
    'lastName': {
      'required': 'Last name is required',
      'minlength': 'Last name must be at least 2 characters'
    },
    'contactEmail': {
      'required': 'Contact email is required',
      'email': 'Email address is not valid'
    },
    'message': {
      'required': 'Message is required',
      'minlength': 'Message must be at least 10 characters',
      'maxlength': 'Message must be at most 240 characters'
    }
  };
  // Error message and HTTP submission failure
  submissionErrorMessage = '';
  submitStatus = 'Submit';
  formSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    private emailService: EmailRequestService) {
      this.initForm();
  }

  /* Lifecycle hooks */

  ngOnInit() {
    this.formSubscription = this.contactForm.valueChanges
      .subscribe(_ => this.onValueChanged());
    this.onValueChanged();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  /* End Lifecycle hooks */

  /**
   * Using form builder, create the contact form group and assign validators
   *
   * @param: none
   *
   * @return: none
  **/
  initForm(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      contactEmail: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(240)]],
      recaptcha: ['', [Validators.required]]
    });
  }

  /**
   * Check form validation on changes and add any error messages to display
   *
   * @params: none
   *
   * @return: none
  **/
  onValueChanged(): void {
    if (!this.contactForm) return;

    const form = this.contactForm;
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.formErrorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  /**
   * Submit form and call emailService submitEmail, clear the form on successful
   * http request, display error if response is not 200
   *
   * @params: none
   *
   * @return: none
  **/
  onSubmit() {
    this.submitStatus = 'Sending...';
    const newEmail = this.contactForm.value;
    newEmail.timestamp = new Date().toString();
    this.emailService.submitEmail(newEmail)
      .subscribe(submissionResponse => {
        if (submissionResponse.statusCode = 200) {
          this.resetForm('Sent!');
          setTimeout(() => {
            this.submitStatus = 'Submit';
          }, 3000);
        }
      }, error => this.submissionErrorMessage = error);
  }

  /**
   * Clear form fields and error messages
   *
   * @params: [status] - defaults to 'Submit', set to 'Sent!' if email was
   * successfully sent
   *
   * @return: none
  **/
  resetForm(status?: string) {
    this.submitStatus = status || 'Submit';
    this.submissionErrorMessage = '';
    this.contactForm.reset();
    for (let i in this.contactForm.controls) {
      this.contactForm.controls[i].setErrors(null);
    }
  }

}
