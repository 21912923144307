/* Component Dependencies */
import { Component } from '@angular/core';

/* Animations */
import { fadeInOut } from '../animations/routing-animations';

/* Interfaces */
import { SkillBarItem } from '../../shared/interfaces/skill-bar-item';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    fadeInOut()
  ]
})
export class AboutComponent {

  skills: Array<SkillBarItem> = [
    { tech: 'JavaScript / TypeScript', level: 9 },
    { tech: 'HTML5 / CSS / Sass',      level: 8 },
    { tech: 'NodeJS',                  level: 8 },
    { tech: 'Angular / Ionic',         level: 7 },
    { tech: 'React',                   level: 6 },
    { tech: 'ExpressJS / Mongoose',    level: 6 },
    { tech: 'MongoDB',                 level: 6 },
    { tech: 'Git',                     level: 6 },
    { tech: 'C / C++',                 level: 5 },
    { tech: 'Python',                  level: 5 }
  ];

  aboutParagraphs: Array<string> = [
    `
      I am a software developer specializing in creating progressive web
      applications. I primarily program using JavaScript / TypeScript, HTML5, CSS,
      Sass, and MongoDB. I have employed Object-Oriented Programming with
      Angular and NodeJS to create end to end software solutions.
    `,
    `
      I’ve completed several programs in my studies such as the Mobile Web
      Specialist nanodegree from Udacity in collaboration with Google and the
      Full Stack Web and Multiplatform Mobile App Development specialization by
      the Hong Kong University of Science and Technology on Coursera. I continue
      to expand my knowledge every day and further hone my skills.
    `,
    `
      I also enjoy building applications using Python, C, and C++ to develop
      Internet of Things devices with platforms such as Arduino, ESP8266, and
      Raspberry Pi. Expanding on my passion for making end to end systems, I
      have also studied the world of C.A.D. and 3D printing to design and
      prototype the physical components of my projects.
    `,
    `
      When not programming, I’m concocting new and interesting home brewed
      beers and mead. I often enhance my brewing with software and IoT to aid in
      crafting a better beverage.
    `
  ];

  constructor() { }

}
