export const pageInfo = [
  {
    title: 'Home',
    route: '/home',
    helpText: 'Navigate to the home page',
    thumbnail: 'assets/images/dark-mountain-800x533.jpg',
    alt: 'Abstract line waves',
    textColor: 'light',
    text: 'Hi, I\'m Andrew',
    subtext: 'Web and Mobile Software Engineer'
  }, {
    title: 'Portfolio',
    route: '/portfolio',
    thumbnail: 'assets/images/portfolio.jpg',
    alt: 'white book with no title',
    textColor: 'dark',
    text: 'My Portfolio',
    helpText: 'Navigate to the portfolio page'
  }, {
    title: 'About',
    route: '/about',
    thumbnail: 'assets/images/about.jpg',
    alt: 'chair in a room',
    textColor: 'light',
    text: 'About Me',
    helpText: 'Navigate to the about me page'
  }, {
    title: 'Contact',
    route: '/contact',
    thumbnail: 'assets/images/contact.jpg',
    alt: 'desk with laptop',
    textColor: 'dark',
    text: 'Contact Me',
    helpText: 'Navigate to the contact me page'
  }
];
