/* Component Dependencies */
import { Component, OnInit } from '@angular/core';

/* Animations */
import { fadeInOut } from '../animations/routing-animations';

/* Interfaces */
import { ProjectData } from '../../shared/project-data';

/* Constants */
import { SAMPLE } from '../../tmp/sample-project';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: [
    fadeInOut()
  ]
})
export class PortfolioComponent implements OnInit {

  url = 'assets/';
  projects: ProjectData[];
  projectList: ProjectData[];

  constructor() { }

  /* Lifecycle hooks */

  ngOnInit() {
    this.projectList = SAMPLE;
    this.projects = SAMPLE;
    // TODO: change to request for projects
  }

  /* End lifecycle hooks */

  /**
   * Filter projects list to display by the selected technology name
   *
   * @params: selected - the technology name associated with projects to display
   *
   * @return: none
  **/
  filterProjectsByTechnology(selected: string): void {
    this.projects = this.projectList.filter(project => {
      return project.technology.includes(selected);
    });
  }

  /**
   * Clear projects filter
   *
   * @params: none
   *
   * @return: none
  **/
  resetProjects(): void {
    this.projects = this.projectList;
  }

}
