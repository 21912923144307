/* Component Dependencies */
import { Component } from '@angular/core';

@Component({
  selector: 'app-resume-download',
  templateUrl: './resume-download.component.html',
  styleUrls: ['./resume-download.component.scss']
})
export class ResumeDownloadComponent {

  resumeURL = 'assets/pdf/Andrew-Wanex-resume.pdf';

  constructor() { }

}
